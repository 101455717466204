/* eslint-disable sonarjs/no-duplicate-string */
import styled from 'styled-components';

import { boxShadows } from '@src/branding/boxShadow';
import { BodilColor } from '@src/branding/color';

import { ProfileDropdownProps } from './ProfileDropdown';

export const Menu = styled.div<Pick<ProfileDropdownProps, 'variant'>>(({ variant }) => ({
  position: 'absolute',
  right: 0,
  backgroundColor: variant === 'light' ? BodilColor.base.white : BodilColor.base.default,
  borderRadius: '8px',
  width: '180px',
  height: 'fit-content',
  marginTop: '8px',
  padding: '8px 16px 8px 8px',
  fontSize: '14px',
  color: variant === 'light' ? BodilColor.base.default : BodilColor.base.white,
  display: 'flex',
  flexDirection: 'column',
  ...boxShadows.small,
  zIndex: 1000,
}));

export const Container = styled.div<Pick<ProfileDropdownProps, 'variant'>>(({ variant }) => ({
  width: 'fit-content',
  height: 'fit-content',
  display: 'flex',
  flexFlow: 'column wrap',
  alignItems: 'flex-end',
  color: variant === 'dark' ? BodilColor.base.default : BodilColor.base.white,
  zIndex: 1000,
}));

export const Header = styled.div({
  height: 40,
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  cursor: 'pointer',
  userSelect: 'none',
});

export const Label = styled.div({
  fontSize: '14px',
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
});

export const Button = styled.div({
  cursor: 'pointer',
  width: '100%',
  textAlign: 'right',
  margin: '4px 0 4px',
});
