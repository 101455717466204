import { navigate } from 'gatsby';
import { CSSProperties, useRef, useState } from 'react';
import styled from 'styled-components';

import { BodilColor } from '@src/branding/color';
import { Text } from '@src/components/Typography';
import { useClickOutside } from '@src/hooks/useClickOutside';
import { redirectToLogin } from '@src/hooks/usePrivateRoute';
import { User } from '@src/model/graphql';
import { cookieStore } from '@src/store/local';

import { Icon } from '../Icon';
import { Button, Container, Header, Label, Menu } from './style';

const Circle = styled.div({
  width: 32,
  height: 32,
  backgroundColor: BodilColor.base.almond,

  borderRadius: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '4px',
});

export type ProfileDropdownProps = {
  style?: CSSProperties;
  variant?: 'light' | 'dark';
  user?: Pick<User, 'firstName' | 'lastName' | 'isAdmin'>;
};

export const ProfileDropdown = ({ user, style, variant = 'dark' }: ProfileDropdownProps) => {
  const { firstName, lastName, isAdmin } = user ?? {};

  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));

  const [open, setOpen] = useState<boolean>();

  const firstInitial = firstName?.[0];
  const lastInitial = lastName?.[0];

  return (
    <Container ref={ref} style={style} variant={variant}>
      <div style={{ position: 'relative' }}>
        <Header onClick={() => setOpen(!open)}>
          <Circle>
            <Text>{firstInitial && lastInitial ? `${firstInitial}${lastInitial}` : 'NN'}</Text>
          </Circle>

          <Label>
            &nbsp;{firstName ? ` ${firstName} ${lastInitial}` : `No name`}&nbsp;
            <Icon.Chevron direction={open ? 'up' : 'down'} />
          </Label>
        </Header>

        {open && (
          <Menu variant={variant}>
            {isAdmin && (
              <Button
                onClick={() => {
                  setOpen(false);
                  navigate('/heat-pump/outdoor-units-price');
                }}
              >
                Admin
              </Button>
            )}

            <Button
              onClick={() => {
                setOpen(false);

                cookieStore().clear('accessToken');
                cookieStore().clear('refreshToken');

                redirectToLogin();
              }}
            >
              Log ud
            </Button>
          </Menu>
        )}
      </div>
    </Container>
  );
};
