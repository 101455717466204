import { cacheExchange, createClient, dedupExchange } from 'urql';

import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';

import { configureAuthExchange } from './auth';

export const createGraphQLClient = () =>
  createClient({
    url: getBackendURI(),
    maskTypename: true,
    exchanges: [configureAuthExchange(), dedupExchange, cacheExchange, multipartFetchExchange],
  });

const getBackendURI = (path = 'graphql/v1') => `${process.env.GATSBY_BACKEND_HOST}/${path}`;
