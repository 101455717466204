import { useMemo } from 'react';

import { useLocation } from '@src/context/location';

export const useBodilClient = (path: string) => {
  const { href } = useLocation() ?? {};

  return useMemo(() => {
    const baseUrl = process.env.GATSBY_BODIL_URL;

    const redirectUrl = path.includes('login') && href ? `?redirectUrl=${encodeURI(href)}` : '';

    return `${baseUrl}${path}${redirectUrl ?? ''}`;
  }, [href, path]);
};
