import { cookieStore } from '@src/store/local';

export const isAuthenticated = () => {
  const payload = getAccessTokenPayload();
  if (!payload) {
    return false;
  }

  const { iat, exp } = payload;
  const now = Math.round(Date.now() * 1e-3);

  return iat <= now && exp > now;
};

export const getAccessTokenPayload = () => {
  const accessToken = cookieStore().get('accessToken');
  if (!accessToken) {
    return {};
  }

  return parseJWT(accessToken);
};

export const parseJWT = (token: string | undefined) => {
  try {
    return JSON.parse(atob(token?.split('.')?.[1] ?? ''));
  } catch {
    return undefined;
  }
};
