import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

export type IconProps = {
  children?: ReactNode;
  width?: number | string;
  height?: number | string;
  color?: 'light' | 'dark' | string;
  icon?: string;
  style?: CSSProperties;
  onClick?: () => void;
  inline?: boolean;
};

const Wrapper = styled.div<IconProps>(({ color = 'black', inline, width: inputWidth, height: inputHeight }) => {
  const width = inputWidth ?? (inputHeight ? undefined : '20px');
  const height = inputHeight ?? (inputWidth ? undefined : '20px');

  return {
    svg: { width, height },
    path: { fill: color },
    '.no-fill': { fill: 'unset' },
    display: inline ? 'inline-block' : 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width,
    height,
    aspectRatio: width && height ? undefined : `1 / 1`,
  };
});

type Props = IconProps & { children?: ReactNode };

export const IconWrapper = ({ color, style, children, ...props }: Props) => (
  <>
    <Wrapper style={style} color={color} {...props}>
      {children}
    </Wrapper>
  </>
);
