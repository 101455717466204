import { RefObject, useEffect } from 'react';

export const useClickOutside = <T extends HTMLElement>(ref: RefObject<T>, handler: () => void) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref?.current && !ref.current.contains(event.target as HTMLElement)) {
        handler();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
};
