import { PageProps } from 'gatsby';
import omit from 'lodash/omit';
import { ReactNode, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Client, Provider as GraphQLProvider } from 'urql';

import { EnvironmentContextProvider } from '@src/context/environment';
import { GraphQLClientProvider } from '@src/context/graphqlClient';
import { LocationContextProvider } from '@src/context/location';
import { ModalContextProvider, Modals, showNextModal } from '@src/context/modal';
import { ToasterContextProvider } from '@src/context/toaster';
import { createGraphQLClient } from '@src/graphql/client';
import { useEnvironment } from '@src/hooks/useEnvironment';

import { ErrorBoundary } from './ErrorBoundary';
import { enableSentry } from './sentry';

enableSentry();

const ModalProvider = ({ children }: { children?: ReactNode }) => {
  const [modals, setModals] = useState<Modals>({});

  return (
    <ModalContextProvider
      value={{
        showModal: (modal, key) => {
          setTimeout(() => {
            const entry = { modal, order: Object.keys(modals).length };
            setModals({ [key]: entry, ...modals });
          });
        },
        hideModal: key => setModals(omit(modals, key)),
      }}
    >
      <Helmet bodyAttributes={{ class: Object.keys(modals).length > 0 ? 'no-scroll' : undefined }}>
        <title>Bodil Energi - Admin</title>

        <link rel='icon' type='image/png' href='/icons/favicon.ico' sizes='16x16' />
      </Helmet>
      {children}
      {showNextModal(modals)}
    </ModalContextProvider>
  );
};

export const pageWrapper = ({ element, props }: { element: ReactNode; props: PageProps }) => {
  const { location } = props;

  return (
    <ErrorBoundary>
      <ToasterContextProvider>
        <GraphQLWrapper>
          <LocationContextProvider value={{ location, props }}>
            <EnvironmentWrapper>
              <ModalProvider>{element}</ModalProvider>
            </EnvironmentWrapper>
          </LocationContextProvider>
        </GraphQLWrapper>
      </ToasterContextProvider>
    </ErrorBoundary>
  );
};

type ChildrenProps = { children?: ReactNode };

const GraphQLWrapper = ({ children }: ChildrenProps) => {
  const [client, setClient] = useState<Client>(createGraphQLClient());

  return (
    <GraphQLClientProvider value={{ resetClient: () => setClient(createGraphQLClient()) }}>
      <GraphQLProvider value={client}>{children}</GraphQLProvider>
    </GraphQLClientProvider>
  );
};

const EnvironmentWrapper = ({ children }: ChildrenProps) => {
  const environmentValues = useEnvironment();

  return <EnvironmentContextProvider {...{ value: environmentValues }}>{children}</EnvironmentContextProvider>;
};
