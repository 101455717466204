import styled from 'styled-components';

import { boxShadows } from '@src/branding/boxShadow';
import { BodilColor } from '@src/branding/color';
import { HEADER_HEIGHT } from '@src/branding/constants';
import { defaultSideMargin, PageMargin } from '@src/branding/spacing';

export const StyledHeader = styled.div<{ margin?: PageMargin }>(({ margin = 'both' }) => ({
  width: '100%',
  position: 'fixed',
  lineHeight: '23px',
  fontWeight: 500,
  left: 0,
  top: 0,
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...boxShadows.large,
  fontSize: '18px',
  ...defaultSideMargin(margin),
  background: BodilColor.base.white,
  zIndex: 2000,
  height: `${HEADER_HEIGHT}px`,
}));

export const StyledButton = styled.div<{ selected?: boolean; disabled?: boolean }>(({ selected, disabled }) => ({
  margin: 'auto 24px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  cursor: disabled ? 'default' : 'pointer',
  color: disabled ? BodilColor.greyscale[20] : undefined,
  borderBottom: selected ? `2px solid ${BodilColor.base.default}` : undefined,
}));

export const RightButtonsWrapper = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  margin: 'auto 0 auto auto',
});

export const Navigation = styled.div({
  fontSize: '16px',
  letterSpacing: '-.9px',
  wordSpacing: 0.5,
  fontWeight: 450,
  display: 'flex',
  justifyContent: 'space-between',
});

export const NavigationItem = styled.a({
  paddingRight: '32px',
});

export const Logo = styled.a<{ src: string }>(({ src }) => ({
  height: '30px',
  width: '95px',
  backgroundImage: `url('${src}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  margin: 'auto 32px auto 32px',
}));
