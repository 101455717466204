import isBoolean from 'lodash/isBoolean';
import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { DESKTOP_FOOTER_HEIGHT, HEADER_HEIGHT } from '@src/branding/constants';
import { defaultSideMargin, PageMargin } from '@src/branding/spacing';
import { useRedirectOnPrivateRoute } from '@src/hooks/usePrivateRoute';

import { DashboardHeader } from './Header';

import '@src/branding/base/font.css';
import '@src/branding/styles.css';

const backgroundImageStyle: CSSProperties = {
  content: '""',
  backgroundSize: 'cover',
  position: 'absolute',
  top: HEADER_HEIGHT,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: -1,
  opacity: 0.25,
  backgroundAttachment: 'fixed',
};

export const Page = styled.div<{
  isMobile?: boolean;
  padding?: string;
  backgroundStyle?: CSSProperties;
  bodyMargin?: PageMargin;
}>(({ bodyMargin = 'both', isMobile, padding, backgroundStyle }) => ({
  width: '100%',
  ...(backgroundStyle
    ? {
        position: 'relative',
        minHeight: '100vh',
        '&:before': {
          ...backgroundImageStyle,
          ...backgroundStyle,
        },
      }
    : { height: '100%', background: 'white' }),
  ...(isMobile ? { paddingLeft: padding, paddingRight: padding } : {}),
  ...(isMobile ? { paddingLeft: padding, paddingRight: padding } : defaultSideMargin(bodyMargin)),
}));

type Props = {
  children?: React.ReactNode;
  pageStyle?: CSSProperties;
  header?: ReactNode;
  footer?: ReactNode;
  bodyMargin?: PageMargin;
  headerMargin?: PageMargin;
};

export const Root = ({
  children,
  pageStyle,
  header = true,
  footer = true,
  bodyMargin,
  headerMargin = bodyMargin,
}: Props) => {
  useRedirectOnPrivateRoute();

  const showDefaultHeader = isBoolean(header) && header;

  const footerHeight = DESKTOP_FOOTER_HEIGHT;
  const pageHeight = `calc(100% - ${footer ? footerHeight : 0}px)`;

  return (
    <div>
      {showDefaultHeader ? <DashboardHeader margin={headerMargin} /> : header}

      <div
        style={{
          minHeight: pageHeight,
          display: 'flex',
          flexFlow: 'column wrap',
        }}
      >
        <Page
          bodyMargin={bodyMargin}
          style={{
            ...pageStyle,
            minHeight: pageHeight,
            ...(header && { paddingTop: HEADER_HEIGHT }),
          }}
          padding={'calc(100% / 16)'}
        >
          {children}
        </Page>
      </div>

      {footer}
    </div>
  );
};
