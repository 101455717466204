exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-batteries-index-tsx": () => import("./../../../src/pages/batteries/index.tsx" /* webpackChunkName: "component---src-pages-batteries-index-tsx" */),
  "component---src-pages-batteries-new-tsx": () => import("./../../../src/pages/batteries/new.tsx" /* webpackChunkName: "component---src-pages-batteries-new-tsx" */),
  "component---src-pages-batteries-price-tsx": () => import("./../../../src/pages/batteries/price.tsx" /* webpackChunkName: "component---src-pages-batteries-price-tsx" */),
  "component---src-pages-batteries-unit-id-tsx": () => import("./../../../src/pages/batteries/[unitID].tsx" /* webpackChunkName: "component---src-pages-batteries-unit-id-tsx" */),
  "component---src-pages-constants-tsx": () => import("./../../../src/pages/constants.tsx" /* webpackChunkName: "component---src-pages-constants-tsx" */),
  "component---src-pages-dashboard-data-tsx": () => import("./../../../src/pages/dashboard-data.tsx" /* webpackChunkName: "component---src-pages-dashboard-data-tsx" */),
  "component---src-pages-heat-pump-expenses-price-tsx": () => import("./../../../src/pages/heat-pump/expenses/price.tsx" /* webpackChunkName: "component---src-pages-heat-pump-expenses-price-tsx" */),
  "component---src-pages-heat-pump-indoor-units-index-tsx": () => import("./../../../src/pages/heat-pump/indoor-units/index.tsx" /* webpackChunkName: "component---src-pages-heat-pump-indoor-units-index-tsx" */),
  "component---src-pages-heat-pump-indoor-units-new-tsx": () => import("./../../../src/pages/heat-pump/indoor-units/new.tsx" /* webpackChunkName: "component---src-pages-heat-pump-indoor-units-new-tsx" */),
  "component---src-pages-heat-pump-indoor-units-price-tsx": () => import("./../../../src/pages/heat-pump/indoor-units-price.tsx" /* webpackChunkName: "component---src-pages-heat-pump-indoor-units-price-tsx" */),
  "component---src-pages-heat-pump-indoor-units-unit-id-tsx": () => import("./../../../src/pages/heat-pump/indoor-units/[unitID].tsx" /* webpackChunkName: "component---src-pages-heat-pump-indoor-units-unit-id-tsx" */),
  "component---src-pages-heat-pump-outdoor-units-index-tsx": () => import("./../../../src/pages/heat-pump/outdoor-units/index.tsx" /* webpackChunkName: "component---src-pages-heat-pump-outdoor-units-index-tsx" */),
  "component---src-pages-heat-pump-outdoor-units-new-tsx": () => import("./../../../src/pages/heat-pump/outdoor-units/new.tsx" /* webpackChunkName: "component---src-pages-heat-pump-outdoor-units-new-tsx" */),
  "component---src-pages-heat-pump-outdoor-units-price-tsx": () => import("./../../../src/pages/heat-pump/outdoor-units-price.tsx" /* webpackChunkName: "component---src-pages-heat-pump-outdoor-units-price-tsx" */),
  "component---src-pages-heat-pump-outdoor-units-unit-id-tsx": () => import("./../../../src/pages/heat-pump/outdoor-units/[unitID].tsx" /* webpackChunkName: "component---src-pages-heat-pump-outdoor-units-unit-id-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inverters-index-tsx": () => import("./../../../src/pages/inverters/index.tsx" /* webpackChunkName: "component---src-pages-inverters-index-tsx" */),
  "component---src-pages-inverters-new-tsx": () => import("./../../../src/pages/inverters/new.tsx" /* webpackChunkName: "component---src-pages-inverters-new-tsx" */),
  "component---src-pages-inverters-price-tsx": () => import("./../../../src/pages/inverters/price.tsx" /* webpackChunkName: "component---src-pages-inverters-price-tsx" */),
  "component---src-pages-inverters-unit-id-tsx": () => import("./../../../src/pages/inverters/[unitID].tsx" /* webpackChunkName: "component---src-pages-inverters-unit-id-tsx" */),
  "component---src-pages-packages-batteries-tsx": () => import("./../../../src/pages/packages/batteries.tsx" /* webpackChunkName: "component---src-pages-packages-batteries-tsx" */),
  "component---src-pages-packages-heat-pumps-tsx": () => import("./../../../src/pages/packages/heat-pumps.tsx" /* webpackChunkName: "component---src-pages-packages-heat-pumps-tsx" */),
  "component---src-pages-packages-solar-products-tsx": () => import("./../../../src/pages/packages/solar-products.tsx" /* webpackChunkName: "component---src-pages-packages-solar-products-tsx" */),
  "component---src-pages-solar-panels-expenses-price-tsx": () => import("./../../../src/pages/solar-panels/expenses/price.tsx" /* webpackChunkName: "component---src-pages-solar-panels-expenses-price-tsx" */),
  "component---src-pages-solar-panels-index-tsx": () => import("./../../../src/pages/solar-panels/index.tsx" /* webpackChunkName: "component---src-pages-solar-panels-index-tsx" */),
  "component---src-pages-solar-panels-new-tsx": () => import("./../../../src/pages/solar-panels/new.tsx" /* webpackChunkName: "component---src-pages-solar-panels-new-tsx" */),
  "component---src-pages-solar-panels-price-tsx": () => import("./../../../src/pages/solar-panels/price.tsx" /* webpackChunkName: "component---src-pages-solar-panels-price-tsx" */),
  "component---src-pages-solar-panels-unit-id-tsx": () => import("./../../../src/pages/solar-panels/[unitID].tsx" /* webpackChunkName: "component---src-pages-solar-panels-unit-id-tsx" */),
  "component---src-pages-upload-file-tsx": () => import("./../../../src/pages/upload-file.tsx" /* webpackChunkName: "component---src-pages-upload-file-tsx" */),
  "component---src-pages-webshop-tsx": () => import("./../../../src/pages/webshop.tsx" /* webpackChunkName: "component---src-pages-webshop-tsx" */)
}

