import noop from 'lodash/noop';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ToasterConfig } from '@src/model/toaster';

import { Toaster } from '../components/Toaster';

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: '60px',
  right: '40px',
  width: 'fit-content',
  zIndex: 1e6,
});

type ToasterContext = {
  showToaster: (toaster: ToasterConfig) => void;
};

const toasterContext = createContext<ToasterContext>({ showToaster: noop });

export const ToasterContextProvider = ({ children }: { children?: ReactNode }) => {
  const [toasters, setToasters] = useState<ToasterConfig[]>([]);

  const [timerHandle, setTimerHandle] = useState<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(timerHandle);
    if (toasters.length > 0) {
      setTimerHandle(setTimeout(() => setToasters(toasters.slice(1)), 3000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toasters.length]);

  const showToaster = (toaster: ToasterConfig) => {
    const { message } = toaster;
    if (toasters.some(inner => inner.message === message)) {
      return;
    }

    setToasters([...toasters, toaster]);
  };

  return (
    <toasterContext.Provider value={{ showToaster }}>
      {children}
      <Wrapper>
        {toasters.map(toaster => (
          <Toaster {...toaster} key={toaster.message} />
        ))}
      </Wrapper>
    </toasterContext.Provider>
  );
};

export const useToasters = () => useContext<ToasterContext>(toasterContext).showToaster;
