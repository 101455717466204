import styled, { CSSProperties } from 'styled-components';

import { Icon } from '@src/components/Icon';

const Container = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  cursor: 'pointer',
  fontWeight: 300,
  height: 'fit-content',
});

type Props = {
  onClick: () => void;
  label?: string;
  style?: CSSProperties;
};

export const BackButton = ({ label, onClick, style }: Props) => {
  return (
    <Container {...{ onClick, style }}>
      <Icon.Arrow direction='left' style={{ marginRight: '8px' }} />
      {label ?? <p>Tilbage</p>}
    </Container>
  );
};
