import { useEffect } from 'react';

import { useLocation } from '@src/context/location';
import { isAuthenticated } from '@src/util/auth';

const LOGIN_URL = process.env.GATSBY_LOGIN_URL;

const PUBLIC_ROUTES = ['/404', '/500', '/terms', '/privacy', '/cookies'];

const REDIRECT_DELAY_MS = 2000;

export const isPrivateRoute = (path: string, publicRoutes: string[]) =>
  !publicRoutes.some(route => route === path?.replace(/\/$/, ''));

export const useRedirectOnPrivateRoute = () => {
  const { pathname } = useLocation() ?? {};

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!pathname || !isPrivateRoute(pathname, PUBLIC_ROUTES) || isAuthenticated()) {
        return;
      }

      redirectToLogin();
    }, REDIRECT_DELAY_MS);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const redirectToLogin = () =>
  (globalThis.location.href = `${LOGIN_URL}?redirectUrl=${globalThis.location.href}`);
