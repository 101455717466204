import { OperationResult, UseMutationState, UseQueryState } from 'urql';

import { CustomError } from '@src/util';

export const getErrors = (result?: Partial<OperationResult>) => {
  return result?.error?.graphQLErrors?.map(({ extensions }) => extensions?.exception as CustomError) || [];
};

export const getFirstError = (result: UseMutationState | UseQueryState | OperationResult): CustomError => {
  return result?.error?.graphQLErrors[0]?.extensions?.exception as CustomError;
};
