import { CurrentUserDocument } from '@src/graphql';

import { useAuthenticatedQuery } from './useAuthenticatedAPICall';

export const useFetchCurrentUser = () => {
  const [result] = useAuthenticatedQuery({
    query: CurrentUserDocument,
    requestPolicy: 'cache-and-network',
  });

  return result.data?.me;
};
