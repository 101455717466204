import { useEffect, useState } from 'react';

import { useLocation } from '@src/context/location';
import { localStore } from '@src/store/local';

export const useEnvironment = () => {
  const [environment, setEnvironment] = useState({ isDebug: false, isLocalhost: false, isStaging: false });

  const location = useLocation();

  const { href } = location ?? {};

  useEffect(() => {
    const isDebug = localStore().get('debug') === 'true';
    const isLocalhost = !!href?.includes('localhost');
    const isStaging = !!href?.includes('staging');

    setEnvironment({ isDebug, isLocalhost, isStaging });
  }, [href]);

  return environment;
};
