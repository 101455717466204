import noop from 'lodash/noop';
import { createContext, useContext } from 'react';

export type GraphQLClientContext = {
  resetClient: () => void;
};

const context = createContext<GraphQLClientContext>({
  resetClient: noop,
});

export const useGraphQLClient = () => useContext<GraphQLClientContext>(context);

export const GraphQLClientProvider = context.Provider;
