import Cookies, { CookieAttributes } from 'js-cookie';
import noop from 'lodash/noop';
import pick from 'lodash/pick';

type LocalStoreKey = 'debug';
type CookieKey = 'accessToken' | 'refreshToken';

const getHostDomain = () =>
  globalThis?.location?.hostname?.includes('localhost')
    ? globalThis?.location?.hostname ?? ''
    : `.${globalThis?.location?.hostname?.split('.').slice(1).join('.')}`;

const cookieOption: CookieAttributes = {
  domain: getHostDomain(),
  expires: 1,
  path: '/',
  sameSite: 'Strict',
  secure: true,
};

const keyWithSuffix = (key: CookieKey) =>
  globalThis?.location?.hostname?.includes('staging') ? `${key}-staging` : key;

export const cookieStore = () => {
  return typeof window === 'undefined'
    ? { set: noop, get: () => '', clear: noop, clearAll: noop }
    : {
        set: (key: CookieKey, data: string) => Cookies.set(keyWithSuffix(key), data, cookieOption),
        get: (key: CookieKey) => Cookies.get(keyWithSuffix(key)),
        clear: (key: CookieKey) => Cookies.remove(keyWithSuffix(key), pick(cookieOption, ['domain', 'path'])),
        clearAll: noop,
      };
};

export const localStore = () => {
  return typeof window === 'undefined'
    ? {
        set: noop,
        get: () => '',
        clear: noop,
        clearAll: noop,
      }
    : {
        set: (key: LocalStoreKey, data?: string) => (data ? localStorage.setItem(key, data) : undefined),
        get: (key: LocalStoreKey) => localStorage.getItem(key),
        clear: (key: LocalStoreKey) => localStorage.removeItem(key),
        clearAll: () => localStorage.clear(),
      };
};
