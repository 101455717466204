import * as Sentry from '@sentry/react';

import { getEnvironment } from '@src/util/environment';

// Set tracesSampleRate to 1.0 to capture 100%
// of transactions for performance monitoring.
// We recommend adjusting this value in production
const TSR_STAGING = 1.0;
const TSR_PRODUCTION = 0.01;

const GATSBY_SENTRY_DSN = process.env.GATSBY_SENTRY_DSN;

const ENV = getEnvironment();

const SENTRY_OPTIONS = {
  dsn: GATSBY_SENTRY_DSN,
  environment: ENV,
  integrations: [Sentry.browserProfilingIntegration(), Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  profileSampleRate: ENV === 'production' ? TSR_PRODUCTION : TSR_STAGING,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  tracesSampleRate: ENV === 'production' ? TSR_PRODUCTION : TSR_STAGING,
};

export const enableSentry = () => {
  if (GATSBY_SENTRY_DSN) {
    Sentry.init(SENTRY_OPTIONS);
  }
};
