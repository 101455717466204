import { DocumentNode } from 'graphql';
import {
  TypedDocumentNode,
  useMutation,
  UseMutationResponse,
  UseMutationState,
  useQuery,
  UseQueryArgs,
  UseQueryResponse,
  UseQueryState,
} from 'urql';

import { getFirstError } from '@src/graphql/error';

import { redirectToLogin } from '../usePrivateRoute';

export const useAuthenticatedQuery = <S, T extends object = object>(args: UseQueryArgs<T, S>) => {
  const [result, execute] = useQuery<S, T>(args);

  if (shouldRedirect(result)) {
    redirectToLogin();
  }

  return [result, execute] as UseQueryResponse<S, T>;
};

export const useAuthenticatedMutation = <S, T extends object = object>(
  args: string | DocumentNode | TypedDocumentNode<S, T>
) => {
  const [result, execute] = useMutation<S, T>(args);

  if (shouldRedirect(result)) {
    redirectToLogin();
  }

  const executeWithPreflight = (preflightArgs: T | undefined) =>
    execute(preflightArgs, { fetchOptions: { headers: { 'apollo-require-preflight': 'true' } } });

  return [result, executeWithPreflight] as UseMutationResponse<S, T>;
};

const shouldRedirect = (result: UseMutationState | UseQueryState) => {
  const { type, message } = getFirstError(result) ?? {};

  return type === 'UNAUTHORIZED' && message === 'you do not have access to perform this action';
};
