import { CSSProperties } from 'styled-components';

export type Device = 'phone' | 'tablet' | 'desktop' | 'widescreen';

const WIDTH_PHONE = 640;
const WIDTH_TABLET = 960;
const WIDTH_WIDESCREEN = 1680;

export const DEVICE_TYPES: Device[] = ['phone', 'tablet', 'desktop', 'widescreen'];

export const styleDeviceSize = (device: Device, style: CSSProperties) => ({
  [`@media only screen and ${deviceQuery(device)}`]: style,
});

const deviceQuery = (device: Device) => {
  switch (device) {
    case 'phone':
      return `(max-width: ${WIDTH_PHONE}px)`;
    case 'tablet':
      return `(min-width: ${WIDTH_PHONE + 1}px) and (max-width: ${WIDTH_TABLET}px)`;
    case 'widescreen':
      return `(min-width: ${WIDTH_WIDESCREEN + 1}px)`;
    case 'desktop':
    default:
      return `(min-width: ${WIDTH_TABLET + 1}px) and (max-width: ${WIDTH_WIDESCREEN}px)`;
  }
};
