import { makeOperation } from '@urql/core';
import { authExchange } from '@urql/exchange-auth';

import { redirectToLogin } from '@src/hooks/usePrivateRoute';
import { LoginResponse } from '@src/model/auth';
import { cookieStore } from '@src/store/local';
import { parseJWT } from '@src/util/auth';

import { RefreshLoginDocument } from './codegen/graphql';
import { getErrors } from './error';

type AuthState = { accessToken?: string };

export const configureAuthExchange = () => {
  return authExchange<AuthState>({
    getAuth: async ({ mutate }) => {
      const accessToken = cookieStore().get('accessToken');
      const refreshToken = cookieStore().get('refreshToken');

      if ((!accessToken || !isAccessTokenValid(accessToken)) && refreshToken) {
        const { data, error } = await mutate<{ refreshLogin: LoginResponse }>(RefreshLoginDocument, { refreshToken });

        if (error || !data) {
          cookieStore().clear('accessToken');
          cookieStore().clear('refreshToken');

          redirectToLogin();

          return { accessToken: undefined };
        }

        if (data && !error) {
          const { accessToken: newAccessToken, refreshToken: newRefreshToken } = data.refreshLogin;

          cookieStore().set('accessToken', newAccessToken);
          cookieStore().set('refreshToken', newRefreshToken);

          return { accessToken };
        }
      }

      return { accessToken };
    },

    addAuthToOperation: ({ authState, operation }) => {
      const { accessToken } = authState ?? {};

      return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
          ...operation.context.fetchOptions,
          headers: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...(operation.context.fetchOptions?.headers ?? {}),
            ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
          },
        },
      });
    },

    didAuthError: ({ error }) => getErrors({ error }).some(innerError => innerError?.type === 'UNAUTHORIZED'),

    willAuthError: ({ authState }) => {
      const { accessToken } = authState ?? {};
      return !accessToken || !isAccessTokenValid(accessToken);
    },
  });
};

const isAccessTokenValid = (accessToken: string | undefined) => {
  const payload = parseJWT(accessToken);

  return Number(payload?.exp ?? '') * 1e3 > Date.now();
};
