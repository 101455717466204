import { navigate } from 'gatsby';
import { ReactNode } from 'react';

import { BodilColor } from '@src/branding/color';
import { PageMargin } from '@src/branding/spacing';
import { ProfileDropdown, ProfileDropdownProps } from '@src/components/Dropdown/ProfileDropdown';
import { useFetchCurrentUser } from '@src/hooks/graphql/useUser';
import { useBodilClient } from '@src/hooks/useBodilClient';
import { Address } from '@src/model';

import { BackButton } from '../BackButton';
import { Logo, StyledHeader } from './style';

type DashboardHeaderProps = ProfileDropdownProps & {
  children?: ReactNode;
  withLogo?: boolean;
  margin?: PageMargin;
  address?: Address;
  back?: { to: string; label?: string };
};

export const DashboardHeader = ({
  children,
  withLogo = true,
  variant = 'light',
  margin,
  address,
  back,
}: DashboardHeaderProps) => {
  const user = useFetchCurrentUser();
  const dashboardUrl = useBodilClient('/overview');

  return (
    <StyledHeader {...{ margin, style: { backgroundColor: BodilColor.base.white, color: BodilColor.base.default } }}>
      {withLogo && !address && !back && <Logo href={dashboardUrl} src='/logo/bodil-dark.png' />}
      <div>
        {back && <BackButton onClick={() => navigate(back.to)} label={back.label} />}
        <div>{children}</div>
      </div>
      <ProfileDropdown user={user} variant={variant === 'light' ? 'dark' : 'light'} />
    </StyledHeader>
  );
};
