import { Typography, type TypographyProps } from 'antd';
import { CSSProperties, ReactNode } from 'react';

const { Text: AntdText } = Typography;

export const Heading = Typography.Title;

type TextProps = Omit<TypographyProps['Text'], '$$typeof'> & {
  children?: ReactNode;
  strong?: boolean;
  style?: CSSProperties;
};

export const Text = ({ children, ...props }: TextProps) => (
  <AntdText
    {...props}
    style={{
      color: 'black',
      opacity: 1,
      fontWeight: 200,
    }}
  >
    {children}
  </AntdText>
);
